<template>
    <div class="mh-card-container" :class="{'padded': padded}">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "CardContainer",
        props: {
            padded: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .mh-card-container {
        font-size: 0.85rem;

        &.padded {
            padding: 1rem 1.5rem;
        }
    }
</style>
